// Abstract specific font names away
$DBHeavent: 'DBHeavent';
$AkzidenzGrotesk: 'AkzidenzGrotesk W1G';
$DBHeaventThin: 'DBHeavent-Thin';
$akzidenz: 'AkzidenzGrotesk W1G', 'DBHeavent' !default;
$helvetica-neue: 'helvetica-neue', 'DBHeavent';
$roboto-condensed: 'Roboto Condensed';
$optimalight: 'OptimaDisplayLight', 'DBHeavent-Thin' !default;
$optimaregular: 'Optima Display Roman', 'OptimaDisplayLight', 'DBHeavent-Thin' !default;
$bb-roman: 'Bauer Bodoni W01 Roman', 'DBHeavent-Thin';
$futuramedium: 'Futura Now Medium', 'DBHeavent' !default;
$futura_now_md: 'Futura Now Medium' !default;
$futuralight: 'Futura Now Regular', 'DBHeavent-Thin' !default;

$boutique_font1: $roboto-condensed, 'DBHeavent';
$boutique_font2: $bb-roman, 'BauerBodoniStd-Roman', 'DBHeavent-Thin', serif;
$boutique_font3: 'OptimaDisplayLight', 'Optima Display Roman', 'DBHeavent-Thin', serif;
$boutique_font4: 'Bauer Bodoni W01 Italic', 'BauerBodoniStd-Italic', 'DBHeavent-Thin', serif;
$boutique_font5: 'AkzidenzGrotesk W1G', 'Helvetica', 'DBHeavent', Arial, sans-serif;
$boutique_font6: 'Optima Display Roman', 'OptimaDisplayLight', 'DBHeavent-Thin';

@font-face {
  font-family: #{$DBHeavent};
  src: url('#{$netstorage-font-path}DBHeavent/DBHeavent.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: #{$DBHeaventThin};
  src: url('#{$netstorage-font-path}DBHeavent/DBHeavent-Thin.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'AkzidenzGrotesk W1G';
  src: url('#{$netstorage-font-path}AkzidenzGrotesk/akzidenz-grotesk-w1g-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'AkzidenzGrotesk W1G';
  src: url('#{$netstorage-font-path}AkzidenzGrotesk/akzidenz-grotesk-w1g-bold.woff2') format('woff2');
  font-display: swap;
  font-weight: bold;
}

body {
  font-family: $DBHeavent;
}

.page-navigation {
  .level-1 {
    font-family: $DBHeavent;
    &.re-nutriv,
    &.aerin,
    &.best-sellers,
    &.gifts {
      font-family: $akzidenz;
    }
  }
  .Estee.Stories {
    .level-1 {
      font-family: $akzidenz;
    }
  }
  .depth-1 {
    h3 {
      font-family: $DBHeavent;
    }
    &.menu-container {
      .level-2 {
        h3 {
          font-family: $DBHeavent;
        }
      }
    }
  }
  .level-3 {
    font-family: $DBHeavent;
  }
}

.page-utilities {
  &__signin-text {
    font-family: $DBHeavent;
  }
}

.page-footer {
  font-family: $DBHeavent;
  p {
    font-family: $DBHeavent;
  }
  .page-sticky-footer {
    &__left {
      .node-elc-nodeblock {
        font-family: $DBHeavent;
      }
    }
    &__right {
      .menu > li {
        font-family: $DBHeavent;
      }
    }
    &__mobile__inner {
      .menu > li {
        font-family: $DBHeavent;
      }
    }
  }
  .special-offers-tray {
    .offers-email-signup-form,
    .offers-register-form,
    .offers-sign-in-form {
      .headline--offers-tray {
        font-family: $optimaregular;
      }
    }
    .offers-email-signup-form {
      .form-item {
        input {
          font-family: $DBHeavent;
        }
      }
      &__sign-in-wrapper {
        p {
          font-family: $DBHeavent;
          a {
            font-family: $DBHeavent;
          }
        }
      }
    }
    .special-offer {
      .special-offer__header {
        font-family: $DBHeavent;
      }
    }
    .special-offer-benefits {
      .special-offer-benefits__link-wrapper a {
        font-family: $DBHeavent;
      }
    }
  }
  .form-text,
  .form-submit {
    font-family: $DBHeavent;
  }
  .footer-legal {
    li {
      &.last {
        font-family: $akzidenz;
      }
    }
  }
}

.multi_use_homepage_module {
  .multi_use_homepage_v1 {
    .headline_content {
      &__explore_link a {
        font-family: $DBHeavent;
      }
      &__subcopy,
      &__subheader {
        strong {
          font-family: $akzidenz;
        }
      }
    }
    .body_copy {
      font-family: $DBHeavent;
    }
  }
}

.olapic-widget {
  &__subhead {
    font-family: $DBHeavent;
  }
  &__heading {
    div {
      font-family: $optimalight !important;
    }
  }
}

.multi_use_mpp_v1 {
  .module_block {
    .body_copy {
      font-family: $akzidenz;
    }
  }
}

a.selectBox {
  font-family: $DBHeavent;
}

.selectBox-options {
  li {
    a {
      font-family: $DBHeavent;
    }
  }
}

.product_brief {
  &__price,
  &__label2,
  &__button--add-to-bag {
    font-family: $DBHeavent;
  }
}

.multi_use_tout_v1 {
  .module_block {
    .body_copy {
      font-family: $akzidenz;
    }
  }
}

body {
  &.section-re-nutriv {
    font-family: $boutique_font1;
    #renutriv_nav {
      font-family: $boutique_font1;
    }
  }
}

#renutriv.boutique {
  h1 {
    font-family: $boutique_font3;
  }
  h2,
  h4 {
    font-family: $boutique_font2;
  }
  h3 {
    font-family: $boutique_font4;
  }
  p,
  a.el-product_shop-now {
    font-family: $boutique_font1;
  }
  &.mpp {
    section.chat-parallax {
      .text-promo.eyebrow,
      .button.cta {
        font-family: $boutique_font1;
      }
      .chat-parallax__headline {
        font-family: $boutique_font2;
      }
    }
    .mpp__product-grid {
      .product-brief {
        &__header {
          font-family: $boutique_font2;
        }
        &__price {
          font-family: $boutique_font5;
        }
      }
    }
  }
  #renutriv_nav dt,
  #section-footer dt {
    font-family: $boutique_font2;
  }
}

#main {
  .custom-mpp.mpp-grid {
    .mpp-grid {
      li.el-product {
        .el-product_price {
          font-family: $DBHeavent;
        }
        .el-product_alt_description-text {
          font-family: $boutique_font5;
        }
        .el-product_shop-now-container a {
          font-family: $roboto-condensed;
        }
        .quickshop-information {
          h5 {
            font-family: 'AkzidenzGrotesk W1G', $DBHeavent;
          }
        }
        .quickshop-container {
          .product-header {
            a {
              font-family: 'AkzidenzGrotesk W1G', $DBHeavent;
            }
          }
        }
      }
    }
    &.renutriv {
      h2 {
        font-family: $boutique_font2;
      }
      .eyebrow,
      .mpp-description,
      .mpp-header .product-sort-container {
        font-family: $boutique_font1;
      }
      li {
        .product-header h3,
        .product-header h4,
        .el-product_alt_description-text {
          font-family: $boutique_font2;
        }
        a.el-product_shop-now {
          font-family: $boutique_font1;
        }
      }
    }
    .eyebrow {
      font-family: $boutique_font5;
    }
    .mpp-description {
      font-family: $boutique_font5;
    }
    .mpp-header {
      .product-sort-container {
        font-family: $boutique_font5;
      }
    }
    &.dark {
      font-family: $boutique_font5;
    }
    &.light {
      font-family: $boutique_font6;
      h2 {
        font-family: $optimalight;
      }
      li {
        .product-header {
          h3,
          h4 {
            font-family: $boutique_font6;
          }
        }
      }
    }
    &.victoria-beckham {
      h2.mpp-title {
        font-family: $boutique_font5;
      }
      li {
        .product-header h3,
        .product-header h4 {
          font-family: $boutique_font5;
        }
      }
    }
  }
}

.section-product {
  .spp-product__details-column {
    .spp-product__details-attribute {
      h5 {
        font-family: $akzidenz;
      }
      .spp-product__details-attribute__label {
        font-family: $DBHeavent;
      }
    }
  }
  .spp-product__how-to-use {
    .spp__steps {
      .spp_howtouse__product-details {
        .text--bold {
          font-family: $DBHeavent;
        }
      }
    }
  }
  .product-full__price-text {
    font-family: $DBHeavent;
    .product-full__price {
      font-family: $DBHeavent;
    }
  }
  .spp__reviews_head {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-review-snapshot {
          .pr-review-snapshot-tags {
            .pr-snapshot-tag-def {
              font-family: $akzidenz;
            }
          }
        }
      }
    }
  }
}

.brand-renutriv {
  .product-full {
    &__description-section {
      .product-full__title {
        font-family: $boutique_font2;
      }
      .product_brief__sub-header {
        font-family: $bb-roman;
      }
    }
    #BVBrowserWebkit {
      .BVRRRatingSummaryLinks {
        span {
          font-family: $boutique_font1;
        }
      }
    }
    &__attribute {
      h5,
      p {
        font-family: $roboto-condensed;
      }
    }
    &__detail-link {
      font-family: $boutique_font1;
    }
    &__price-text {
      font-family: $DBHeavent;
    }
    #engraving_block {
      .product-full__engraving {
        font-family: $akzidenz;
      }
    }
    &__button-container {
      .quantity {
        .selectBox-label {
          font-family: $boutique_font1;
        }
      }
      .product-full__add-button {
        font-family: $roboto-condensed;
      }
      .free_standard_shipping_returns {
        font-family: $akzidenz;
      }
    }
    &__personal {
      .product-full__wishlist {
        font-family: $boutique_font1;
      }
    }
    .review-snippet {
      .pr-snippet {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count,
          a.pr-snippet-write-review-link {
            font-family: $roboto-condensed;
          }
        }
      }
    }
  }
  .spp-product {
    &__details-container {
      .spp-product__details-header {
        font-family: $bb-roman;
      }
    }
    &__anchor {
      &--scroll {
        font-family: $boutique_font1;
      }
    }
    &__mini-bag {
      &-description {
        .spp-product__mini-bag-header,
        .spp-product__mini-bag-sub-header {
          font-family: $bb-roman;
        }
      }
      &-price-size {
        font-family: $optimaregular;
      }
      &-selection {
        .spp-product__mini-bag-quantity {
          font-family: $boutique_font1;
        }
      }
      &-add-button {
        font-family: $DBHeavent;
      }
    }
    &__details {
      &-column01,
      &-column02 {
        .spp-product__details-description {
          font-family: $boutique_font1;
        }
        .spp-product__details-attribute {
          h5,
          p,
          ul,
          li {
            font-family: $boutique_font1;
          }
        }
      }
    }
  }
  #main {
    .chat-parallax,
    .parallax__container {
      &__content {
        .chat-parallax__headline {
          font-family: $bb-roman;
        }
      }
    }
    .discover-more {
      &__header {
        font-family: $boutique_font2;
      }
      .discover_more__product-price {
        font-family: $roboto-condensed;
      }
      &__product-quickshop {
        font-family: $akzidenz;
      }
    }
    #pr-write,
    #pr-reviewdisplay,
    .product-brief,
    .mpp__product {
      .p-w-r {
        a {
          font-family: $roboto-condensed;
        }
      }
    }
  }
}

.brand-aerin {
  #main {
    .mpp--brand-aerin {
      .product_brief {
        .product_brief__price {
          font-family: $DBHeavent;
        }
      }
      .product_brief__buttons-container {
        .button__quickshop,
        .product-brief__view-details {
          font-family: $DBHeavent;
        }
      }
    }
  }
  &.section-world-aerin {
    .page-main {
      p {
        font-family: $futura_now_reg;
      }
    }
  }
}

.colorbox__quickshop {
  #cboxWrapper {
    .quickshop {
      .quickshop__tab-container {
        .quickshop__tab,
        &.quickshop__tab--description,
        &.ellipsis {
          font-family: $DBHeavent;
        }
      }
      .quickshop__description-section {
        .quickshop__price-size-select {
          .selectBox-label {
            font-family: $DBHeavent;
          }
        }
      }
      .quickshop__button-container {
        .quickshop__quantity {
          .selectBox-label {
            font-family: $DBHeavent;
          }
        }
      }
      .quickshop__personal {
        .quickshop__wishlist {
          font-family: $DBHeavent;
        }
      }
    }
  }
}

.spp-product__how-to-use {
  .spp-howtouse {
    .spp_howtouse__product-details {
      .text--bold {
        font-family: $DBHeavent;
      }
    }
  }
}

.section-esteestories {
  .es-landing {
    &__filters,
    &__sub-filters {
      font-family: $DBHeavent;
    }
    &__filters__side__wrapper {
      font-family: $optimalight;
    }
  }
  .text--bold {
    font-family: $DBHeavent;
  }
}

#estee-stories-article {
  .es_content {
    h1,
    h2 {
      font-family: $optimalight;
    }
    .ee-first-letter {
      font-family: $optimalight;
    }
    .back-bar__link {
      .text {
        font-family: 'AkzidenzGrotesk W1G', $DBHeavent;
      }
    }
    .es_header-tags {
      font-family: $DBHeavent;
    }
    .basic-textarea-v1 {
      p {
        font-family: $DBHeavent;
      }
    }
    .text--bold {
      font-family: $DBHeavent;
    }
    .ee-article-products__list {
      .cta,
      .sku-brief-editorial .link--bold {
        font-family: $DBHeavent;
      }
    }
  }
}

.section-foundation-finder {
  .ff-results {
    &__recommended-products {
      .sku-brief-editorial__size-price-reviews {
        .text--bold {
          font-family: $DBHeavent;
        }
      }
      .sku-brief-editorial__button-wrapper {
        .button--dark-secondary {
          font-family: $DBHeavent;
        }
      }
    }
  }
  .spp-product__how-to-use {
    .spp-howtouse {
      .spp_howtouse__product-details {
        .text--bold {
          font-family: $DBHeavent;
        }
      }
      .quickshop__add-button {
        font-family: $DBHeavent;
      }
    }
  }
}

.section-promotions {
  .special-offer-row-wrapper {
    .special-offer-text-wrapper {
      .special-offer-copy-block {
        p {
          font-family: $DBHeavent;
        }
      }
    }
  }
}

.device-mobile {
  .page-navigation {
    .level-1 {
      font-family: $optimaregular;
    }
    .Estee {
      &.Stories {
        .level-1 {
          font-family: $optimaregular;
        }
      }
    }
    .depth-1 {
      h3 {
        font-family: $optimaregular;
      }
      &.menu-container {
        .level-2 {
          h3 {
            font-family: $optimaregular;
          }
        }
      }
    }
    .level-3 {
      font-family: $DBHeavent;
    }
  }
  .responsive-carousel {
    h2.responsive-carousel__title,
    .slide__link {
      font-family: $akzidenz;
    }
    .flex-viewport {
      p.slide__caption {
        font-family: $optimalight;
      }
    }
  }
  .page-footer {
    .page-sticky-footer {
      .field-mobile-menu {
        .level-1 {
          font-family: $DBHeavent;
        }
      }
    }
    .responsive-footer {
      .node-elc-nodeblock {
        .footer-forms__header,
        .form-text,
        .form-submit,
        .footer-legal {
          font-family: $DBHeavent;
        }
      }
    }
  }
  .multi_use_mpp_v1 {
    .body_copy {
      font-family: $DBHeavent;
    }
  }
  select {
    &.mpp__product-sort {
      font-family: $DBHeavent;
    }
  }
  .selectBox-options {
    li {
      a {
        font-family: $akzidenz;
      }
    }
  }
  .product-brief {
    &__misc-flag,
    &__button-quickshop,
    &__button-quickshop--close {
      font-family: $DBHeavent;
    }
  }
  .quickshop-inline {
    &__attribute-header,
    &__wishlist:link,
    &__wishlist:visited,
    &__details:link,
    &__details:visited,
    &__add-button {
      font-family: $DBHeavent;
    }
    &__price-size {
      font-family: $akzidenz;
    }
  }
  a.selectBox {
    font-family: $akzidenz;
  }
  .text-promo,
  .cta {
    font-family: $DBHeavent;
  }
  .link--bold {
    font-family: $DBHeavent;
  }
  &.section-product {
    .spp-product {
      &__description {
        .spp-product__attribute {
          h5,
          p {
            font-family: $DBHeavent;
          }
        }
        .spp-product__detail-link,
        .spp-product__price-size-select,
        .spp-product__quantity,
        .free_standard_shipping_returns {
          font-family: $DBHeavent;
        }
        .spp-product__personal {
          a.spp-product__share,
          a.spp-product__wishlist {
            font-family: $DBHeavent;
          }
        }
      }
      &__details {
        li {
          font-family: $DBHeavent;
        }
      }
      &__additional-section {
        .spp-product__details-description {
          font-family: $DBHeavent;
        }
        .spp-product__details-column {
          .spp-product__details-attribute {
            h5,
            p {
              font-family: $DBHeavent;
            }
          }
        }
        .spp-howtouse {
          &__section1 {
            .spp-howtouse__instructions {
              font-family: $DBHeavent;
            }
          }
          &__section2 {
            .text--bold {
              font-family: $DBHeavent;
            }
          }
        }
      }
      &__price-size {
        font-family: $DBHeavent;
      }
    }
    .spp-discover-more-section-mobile {
      .spp-product__section-header,
      .discover_more__product-price,
      .discover_more__product-shopnow {
        font-family: $DBHeavent;
      }
    }
    .basic_infinite_carouselWrapper {
      .hero-tout-mobile {
        p {
          font-family: $DBHeavent !important;
        }
      }
    }
    .spp__reviews_head {
      #pr-reviewdisplay {
        .p-w-r {
          .pr-review-snapshot {
            .pr-snippet {
              .pr-snippet-read-and-write {
                .pr-snippet-review-count,
                .pr-snippet-write-review-link {
                  font-family: $DBHeavent;
                }
              }
            }
            .pr-ratings-histogram {
              .pr-histogram-label,
              .pr-histogram-count {
                font-family: $DBHeavent;
              }
            }
            .pr-review-snapshot-tags {
              .pr-h1,
              .pr-snapshot-tag-def {
                font-family: $DBHeavent;
              }
            }
          }
          .pr-rd-main-header {
            .pr-multiselect {
              span {
                font-family: $DBHeavent;
              }
            }
            .pr-rd-search-reviews-icon-button {
              .pr-search-icon::after {
                font-family: $DBHeavent;
              }
            }
            .pr-rd-review-header-sorts {
              .pr-rd-sort-group:before {
                font-family: $DBHeavent;
              }
              select.pr-rd-sort {
                font-family: $DBHeavent;
              }
            }
          }
          .pr-review {
            .pr-rd-review-headline {
              font-family: $DBHeavent;
            }
            .pr-rd-description {
              .pr-rd-reviewer-details {
                .pr-rd-details {
                  time,
                  span {
                    font-family: $DBHeavent;
                  }
                }
              }
              .pr-rd-description-text {
                font-family: $DBHeavent;
              }
            }
            .pr-accordion {
              .pr-accordion-btn {
                span {
                  font-family: $DBHeavent;
                }
              }
              .pr-rd-subratings {
                dl {
                  dt,
                  dd {
                    font-family: $DBHeavent;
                  }
                }
              }
            }
            .pr-rd-footer {
              span {
                font-family: $DBHeavent;
              }
              .pr-helpful-yes,
              .pr-helpful-no {
                .pr-helpful-count,
                .pr-helpful-count:before,
                .pr-helpful-count:after {
                  font-family: $DBHeavent;
                }
              }
              .pr-rd-flag-review-container {
                a {
                  font-family: $DBHeavent;
                }
              }
            }
            .pr-rd-main-footer {
              .pr-rd-review-position {
                span {
                  font-family: $DBHeavent;
                }
              }
              a.pr-rd-to-top {
                font-family: $DBHeavent;
              }
            }
          }
          .pr-rd-search-reviews-input {
            input {
              font-family: $DBHeavent;
            }
          }
        }
      }
    }
  }
  &.brand-renutriv {
    .page-main {
      font-family: $roboto-condensed;
      h5 {
        font-family: $bb-roman;
      }
    }
    .mpp__product-grid {
      .product-brief {
        &__price {
          font-family: $DBHeavent;
        }
        &__button-quickshop,
        &__button-quickshop--close {
          font-family: $roboto-condensed;
        }
        &__quickshop-container {
          .quickshop-inline {
            &__price-size,
            &__add-button {
              font-family: $roboto-condensed;
            }
          }
        }
      }
    }
    #main {
      .spp-product {
        &__header,
        &__sub-header {
          font-family: $bb-roman;
        }
        &__attribute,
        &__details-attribute {
          h5 {
            font-family: $bb-roman;
          }
          p {
            font-family: $roboto-condensed;
          }
        }
        &__price-size,
        &__quantity {
          font-family: $roboto-condensed;
        }
        &__details {
          &-header {
            font-family: $bb-roman;
          }
        }
      }
      .spp-discover-more-section-mobile {
        .discover-more {
          .discover_more__product-price {
            font-family: $DBHeavent;
          }
        }
      }
      .review-snippet {
        .pr-snippet {
          .pr-snippet-read-and-write {
            .pr-snippet-review-count,
            a.pr-snippet-write-review-link {
              font-family: $roboto-condensed;
            }
          }
        }
      }
    }
  }
  &.brand-aerin {
    #main {
      .mpp {
        .product-brief__price,
        .button__quickshop {
          font-family: $DBHeavent;
        }
      }
      .quickshop-inline {
        &__price-size-select-container {
          .quickshop-inline__price-size {
            font-family: $DBHeavent;
          }
        }
        .quickshop-inline__add-button {
          &.button {
            font-family: $DBHeavent;
          }
        }
      }
    }
  }
  &.section-esteestories {
    .es-landing__filters,
    .es-landing__sub-filters {
      font-family: $boutique_font3;
    }
    .ee-landing__text {
      .text--short {
        font-family: $DBHeavent;
      }
    }
  }
  #estee-stories-article {
    h3 {
      font-family: $optimalight;
    }
    .es_content {
      .basic-textarea-v1 {
        p {
          font-family: $DBHeavent;
        }
      }
    }
    .ee-article-products {
      .text--short {
        font-family: $DBHeavent;
      }
    }
    .ee-disco-more {
      .text--short {
        font-family: $DBHeavent;
      }
    }
  }
  &.section-customer-service {
    .sidebar-page {
      &__content {
        .cs-quick-info {
          &__title {
            font-family: $DBHeavent;
          }
        }
        .basic-textarea-v1 {
          div,
          p,
          li {
            font-family: $DBHeavent;
          }
          p {
            strong {
              font-family: $DBHeavent;
            }
          }
        }
      }
    }
  }
  &.section-promotions {
    .special-offer-row-mobile {
      .special-offer-header {
        .special-offer-eyebrow {
          font-family: $DBHeavent;
        }
      }
      .special-offer-content {
        p {
          font-family: $DBHeavent;
          &.under-cta {
            font-family: $DBHeavent;
          }
        }
      }
    }
    .promo-unit {
      .promo-unit__copy {
        font-family: $DBHeavent;
      }
    }
  }
}

.section-customer-service {
  .sidebar-page {
    &__content {
      .cs-quick-info {
        &__title {
          font-family: $akzidenz;
        }
      }
    }
  }
}
#{$aerin} {
  .page-main {
    font-family: $DBHeavent;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $futura_now_reg;
    }
    .select-markdown,
    select,
    a.selectBox,
    a.selectbox {
      font-family: $DBHeavent;
    }
    .text-product-names-sub,
    .text--long-copy,
    .text-long-body-copy,
    .text-product-anchor,
    .spp-product__anchor,
    .spp-product,
    .product-full {
      font-family: $futura_now_reg;
    }
    .spp-product {
      &__details {
        .spp-product {
          &__details-header,
          &__details-description {
            font-family: $futura_now_reg;
          }
        }
      }
    }
    .button {
      font-family: $futura_now_md;
    }
  }
}

.woa-blog__content {
  .ee-disco-more__label {
    font-family: $futura_now_reg;
  }
}

.aerin-video-block {
  &__pretitle {
    font-family: $futura_now_reg;
  }
  &__play-text {
    font-family: $futura_now_reg;
  }
}

.aerin-image-block-w-overlay {
  &__text {
    &--subtitle {
      font-family: $futura_now_md;
    }
  }
  &__overlay.enable-overlay {
    &__text {
      font-family: $futura_now_reg;
    }
  }
}

.aerin_headline_block {
  &--subtitle {
    font-family: $futura_now_reg;
  }
}

.aerin-slot-button {
  font-family: $futura_now_reg;
}

.product-full {
  #{$aerin} .page-main & {
    font-family: $futura_now_md;
  }
  &__misc-flag {
    #{$aerin} .page-main & {
      font-family: $futura_now_md;
    }
  }
  &__detail-link,
  &__share,
  &__wishlist {
    #{$aerin} .page-main & {
      font-family: $futura_now_md;
    }
  }
}
