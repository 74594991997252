.device-mobile {
  .store-locator {
    .local-search-form__inner {
      .local-search-form__banner {
        img {
          width: 100%;
          margin-top: 5%;
        }
      }
    }
  }
  .appointments-container {
    #booking-step3 {
      .appt-book-book-submit {
        padding: 0 5.2em;
      }
    }
  }
  #appointment-book-container {
    .appt-book-page-header-bg {
      margin-top: 12%;
    }
  }
}

.appointments-page {
  .mobile {
    display: none;
  }
}

.appt-book-location-selector {
  .location-submit {
    font-size: 14px;
  }
}

.appt-book-book-conflict-overlay {
  &.appt-book-overlay {
    .overlay-content-container {
      .overlay-content {
        p {
          margin-top: 42px;
        }
      }
    }
  }
}

.appt-book-book-countermax-overlay {
  &.appt-book-overlay {
    .overlay-content-container {
      p {
        margin: 22px 0px 0px 12px;
      }
    }
  }
}

.appt-book-overlay {
  .overlay-content-container {
    .overlay-content {
      h4 {
        font-size: 19px;
      }
    }
  }
}
