.ff-results {
  .ff-results {
    &__matches-sub-headline,
    &__regimen-steps {
      display: none;
    }
  }
}

.section-customer-service {
  &.device-mobile {
    .main-content {
      @media #{$small-only} {
        overflow: visible;
      }
    }
  }
}

.trust_mark_footer {
  margin-#{$ldirection}: 0px;
  margin-#{$rdirection}: 0px;
  border-top: 1px solid #a4a4ab;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    @include swap_direction(margin, auto);
  }
}

.device-mobile {
  .footer-main-menu {
    .menu {
      a {
        .LPMcontainer {
          a {
            padding-#{$ldirection}: 0;
          }
          div {
            font-size: 21px;
            height: 45px;
            line-height: 2;
          }
        }
      }
    }
  }
  .footer-social-links {
    @media (max-width: 768px) {
      width: auto;
      text-align: center;
      li {
        float: none;
        display: inline-block;
      }
    }
  }
  #colorbox {
    div#cboxWrapper {
      height: auto !important;
    }
    &.signin-overlay-wrapper {
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch;
      @media #{$medium-up} {
        height: 100% !important;
      }
      #cboxWrapper {
        #cboxContent {
          .registration {
            padding-bottom: 55px;
            .sign-in-component__title {
              input[type='radio'] ~ label {
                margin-#{$ldirection}: 1px;
              }
            }
            .address-search-results {
              table {
                width: 100%;
                tr.address-results-row {
                  td {
                    letter-spacing: normal;
                    word-break: break-all;
                    width: 25%;
                  }
                }
              }
            }
          }
        }
        @media #{$medium-up} {
          .sign-in-component {
            width: auto;
            .sign-in-component__form {
              @include swap_direction(margin, 0 30%);
              padding-bottom: 100px;
            }
            .sign-in-component__header {
              width: 300px;
            }
          }
        }
      }
    }
  }
}

.page-footer {
  .footer-social-links {
    .line {
      background-image: url('/media/images/global/line-logo-blue.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      margin-#{$ldirection}: 15px;
    }
  }
}

.mpp {
  .product-brief__quickshop-container {
    .quickshop-inline__phone-button {
      display: none;
    }
  }
}

#colorbox {
  .bisMobileIframe {
    width: 100%;
  }
}

.tiny-waitlist-overlay {
  #waitlist {
    .email_input {
      input[type='email'] {
        width: 100%;
      }
    }
    .align-r {
      text-align: center;
      padding-top: 10px;
      input[type='submit'] {
        height: 40px;
        line-height: 40px;
        @include swap_direction(padding, 0 20px);
      }
    }
  }
}

.device-mobile {
  #colorbox {
    #cboxClose {
      top: 22px;
    }
  }
}

.page-wrapper {
  .product-brief {
    &__button-quickshop,
    &__button-quickshop--close {
      bottom: 0;
      position: absolute;
    }
  }
  .quickshop-inline {
    .spp-product__footer_cta {
      display: none;
    }
  }
}

.brand-aerin {
  .mpp__product-grid {
    .product-brief__quickshop-container {
      .quickshop-inline__add-button,
      .quickshop-inline__details {
        display: block;
      }
    }
  }
}

.device-mobile {
  .page-navigation {
    .menu-ref {
      &__title {
        font-family: $DBHeavent;
        letter-spacing: 0;
      }
    }
    .menu {
      &__item--link {
        letter-spacing: 0;
      }
    }
    .level-1 {
      font-size: 30px;
      line-height: 25px;
      letter-spacing: 0.02em;
      &.re-nutriv,
      &.aerin,
      &.best-sellers,
      &.gifts {
        font-size: 22px;
      }
    }
    .Estee.Stories {
      .level-1 {
        font-size: 22px;
      }
    }
    .depth-1 {
      h3 {
        font-size: 21px;
        letter-spacing: 0.02em;
      }
      &.menu-container {
        .level-2 {
          h3 {
            font-size: 21px;
            letter-spacing: 0.02em;
          }
        }
      }
    }
    .level-3 {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
  .page-utilities {
    &__signin-text {
      font-size: 13px;
      letter-spacing: 0.02em;
    }
    &__cart-button {
      padding-#{$ldirection}: 3px;
    }
  }
  .responsive-carousel {
    h2.responsive-carousel__title,
    .slide__link {
      font-size: 15px;
    }
    .flex-viewport {
      p.slide__caption {
        letter-spacing: 0.02em;
      }
    }
  }
  .page-footer {
    .page-sticky-footer {
      &__right {
        .field-mobile-menu {
          .menu {
            a.level-1 {
              font-size: 15px;
              letter-spacing: 0.05em;
            }
            li.first {
              span {
                font-size: 15px;
                font-family: $DBHeavent;
              }
            }
          }
        }
      }
    }
    .responsive-footer {
      .menu-reference {
        .level-1 {
          letter-spacing: 0.02em;
        }
        .menu {
          li.first {
            .level-1 {
              a {
                padding-left: 0;
              }
            }
          }
        }
      }
      .node-elc-nodeblock {
        .footer-forms__header,
        .form-text,
        .form-submit {
          font-size: 15px;
        }
        .footer-legal {
          font-size: 13px;
        }
      }
    }
  }
  .multi_use_homepage_module {
    .multi_use_homepage_v1 {
      .headline_content {
        &__headline {
          font-size: 14.0625vw !important;
          letter-spacing: 0.02em;
        }
        &__subcopy {
          font-size: 5vw;
          letter-spacing: 0.05em;
        }
        &__cta_wrapper {
          .cta__button {
            font-size: 17px;
            letter-spacing: 0.05em;
          }
        }
        &__subheader {
          font-weight: normal;
          letter-spacing: 0.05em;
        }
      }
      .body_copy {
        font-size: 5vw;
        .explore_link__underline {
          font-size: 5vw;
        }
      }
    }
  }
  .multi_use_mpp_v1 {
    .body_copy {
      font-size: 5vw;
      letter-spacing: 0.05em;
    }
    .headline_content {
      &__headline {
        p {
          font-size: 40px;
          letter-spacing: 0.02em;
        }
      }
    }
  }
  .mpp__header {
    letter-spacing: 0.025em;
  }
  select {
    &.mpp__product-sort {
      font-size: 13px;
      letter-spacing: 0.05em;
    }
  }
  .product-brief {
    &__misc-flag,
    &__button-quickshop,
    &__button-quickshop--close {
      font-size: 17px;
      letter-spacing: 0.05em;
    }
    &__header,
    &__sub-header {
      letter-spacing: 0.02em;
    }
  }
  .quickshop-inline {
    &__attribute-header,
    &__add-button {
      font-size: 17px;
      letter-spacing: 0.05em;
    }
    &__attribute-text {
      letter-spacing: 0.02em;
    }
    &__wishlist:link,
    &__wishlist:visited,
    &__details:link,
    &__details:visited {
      font-size: 15px;
      letter-spacing: 0.05em;
    }
  }
  a.selectBox {
    letter-spacing: 0.05em;
  }
  .hero-tout-mobile {
    &__content {
      .headline--large {
        font-size: 40px;
        letter-spacing: 0.02em;
      }
    }
  }
  .text-promo {
    font-size: 5vw;
    letter-spacing: 0.05em;
  }
  .cta {
    font-size: 17px;
    letter-spacing: 0.05em;
  }
  .ee-tout__content {
    .header__headline {
      letter-spacing: 0.02em;
    }
  }
  .link--bold {
    font-size: 15px;
    letter-spacing: 0.05em;
  }
  &.section-product {
    .spp-product {
      &__description {
        .spp-product__header,
        .spp-product__sub-header {
          letter-spacing: 0.02em;
        }
        .spp-product__attribute {
          h5,
          p {
            font-size: 17px;
            letter-spacing: 0.05em;
          }
        }
        .spp-product__detail-link {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
        .spp-product__price-size-select,
        .spp-product__quantity,
        .free_standard_shipping_returns {
          letter-spacing: 0.05em;
          font-size: 17px !important;
        }
        .spp-product__personal {
          .spp-product__share,
          .spp-product__wishlist {
            font-size: 15px;
            letter-spacing: 0.05em;
          }
        }
      }
      &__price-size {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
      &__details {
        li {
          font-size: 17px;
        }
      }
      &__additional-section {
        .spp-product__details-header {
          font-size: 25px;
          letter-spacing: 0.02em;
        }
        .spp-product__details-description {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
        .spp-product__details-column {
          .spp-product__details-attribute {
            h5,
            p {
              font-size: 17px;
            }
          }
        }
        .spp-howtouse {
          &__section1 {
            .spp-howtouse__header {
              &.headline--secondary {
                span {
                  font-size: 25px;
                  letter-spacing: 0.02em;
                }
              }
            }
            .spp-howtouse__instructions {
              font-size: 17px;
              letter-spacing: 0.05em;
            }
          }
          &__section2 {
            h3,
            h4 {
              letter-spacing: 0.02em;
            }
            .text--bold {
              font-size: 15px;
              letter-spacing: 0.05em;
            }
          }
        }
      }
    }
    .spp-discover-more-section-mobile {
      .spp-product__section-header,
      .discover_more__product-price {
        font-size: 15px;
        letter-spacing: 0.05em;
      }
      .discover_more__product-shopnow {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
    }
    .basic_infinite_carouselWrapper {
      .hero-tout-mobile {
        p {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
      }
    }
    .module_block__container {
      .headline_content__headline,
      .button_style_dark {
        letter-spacing: 0.02em;
      }
    }
    .review-snippet {
      .pr-snippet {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count,
          .pr-snippet-write-review-link {
            font-size: 17px !important;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .spp__reviews_head {
      #pr-reviewdisplay {
        .p-w-r {
          .pr-review-snapshot {
            .pr-snippet {
              .pr-snippet-read-and-write {
                .pr-snippet-review-count,
                .pr-snippet-write-review-link {
                  font-size: 17px !important;
                }
              }
            }
            .pr-ratings-histogram {
              .pr-histogram-label,
              .pr-histogram-count {
                font-size: 15px;
              }
            }
            .pr-review-snapshot-tags {
              .pr-h1,
              .pr-snapshot-tag-def {
                font-size: 15px;
              }
            }
          }
          .pr-rd-main-header {
            .pr-rd-review-total {
              clear: both;
            }
            .pr-multiselect {
              span {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
              .pr-caret-icon {
                background-position: 8px;
                margin-top: -17px;
              }
            }
            .pr-rd-search-reviews-icon-button {
              .pr-search-icon::after {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
            }
            .pr-rd-review-header-sorts {
              .pr-rd-sort-group:before {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
              select.pr-rd-sort {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
            }
          }
          .pr-review {
            .pr-rd-review-headline {
              font-size: 15px;
              letter-spacing: 0.05px;
            }
            .pr-rd-description {
              .pr-rd-reviewer-details {
                .pr-rd-details {
                  time,
                  span {
                    font-size: 15px;
                    letter-spacing: 0.05px;
                  }
                }
              }
              .pr-rd-description-text {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
            }
            .pr-accordion {
              .pr-accordion-btn {
                span {
                  font-size: 15px;
                  letter-spacing: 0.05px;
                }
              }
              .pr-rd-subratings {
                dl {
                  dt,
                  dd {
                    font-size: 15px;
                    letter-spacing: 0.05px;
                  }
                }
              }
            }
            .pr-rd-footer {
              span {
                font-size: 15px;
                letter-spacing: 0.05px;
              }
              .pr-helpful-yes,
              .pr-helpful-no {
                .pr-helpful-count,
                .pr-helpful-count:before,
                .pr-helpful-count:after {
                  font-size: 15px;
                  letter-spacing: 0.05px;
                }
              }
              .pr-rd-flag-review-container {
                a {
                  font-size: 15px;
                  letter-spacing: 0.05px;
                }
              }
            }
          }
          .pr-rd-search-reviews-input {
            input {
              letter-spacing: 0.05px;
            }
          }
        }
      }
    }
  }
  &.section-esteestories {
    .es-landing__filters,
    .es-landing__sub-filters {
      letter-spacing: 0.02px;
    }
    .ee-landing__text {
      .text--bold,
      .text--short {
        font-size: 17px;
        letter-spacing: 0.05px;
      }
      h4 {
        font-size: 40px;
        letter-spacing: 0.02px;
      }
      .ee-subhead {
        font-size: 17px;
      }
    }
  }
  #estee-stories-article {
    .es_header-text-group {
      .es_header-tags,
      .es_header-tags_label {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
      h1.es_header-text_title {
        letter-spacing: 0.02em;
      }
      h2.es_header-text_subtitle {
        font-size: 20px;
        letter-spacing: 0.02em;
      }
    }
    h3 {
      letter-spacing: 0.02em;
    }
    .ee-article-products {
      h2 {
        font-size: 20px;
        letter-spacing: 0.02em;
      }
      .text-product-listing-names-main,
      .text-product-listing-names-sub {
        letter-spacing: 0.02em;
      }
      .text--short,
      .text--bold {
        letter-spacing: 0.05em;
      }
      .link--bold {
        font-size: 13px;
      }
    }
    .ee-disco-more {
      h2 {
        font-size: 30px;
        letter-spacing: 0.02em;
      }
      .text--short,
      .text--bold {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
      .ee-disco-more__headline {
        letter-spacing: 0.02em;
      }
    }
  }
  &.section-customer-service {
    .sidebar-page {
      &__sidebar {
        ul {
          &.menu {
            li {
              a {
                font-size: 17px;
                letter-spacing: 0.05em;
              }
            }
          }
        }
      }
      &__content {
        .sidebar-page__content-title {
          letter-spacing: 0.02em;
        }
        .cs-quick-info {
          &__title {
            font-size: 17px;
            letter-spacing: 0.05em;
          }
          .cs__livechat {
            display: none;
          }
        }
        .basic-textarea-v1 {
          h2 {
            font-size: 30px;
            letter-spacing: 0.02em;
          }
          h5 {
            font-size: 25px;
            letter-spacing: 0.02em;
          }
          div,
          p,
          li {
            font-size: 18px;
          }
          p {
            strong {
              font-size: 18px;
              letter-spacing: 0.05em;
            }
          }
        }
      }
    }
  }
  &.section-promotions {
    .special-offer-row-mobile {
      .special-offer-header {
        .special-offer-headline {
          letter-spacing: 0.02em;
          span {
            font-size: 40px !important;
            letter-spacing: 0.05em !important;
          }
        }
        .special-offer-eyebrow {
          font-size: 15px;
          letter-spacing: 0.05em;
        }
      }
      .special-offer-content {
        p {
          font-size: 18px;
          letter-spacing: 0.05em !important;
          &.under-cta {
            font-size: 15px;
            margin-top: 0;
          }
        }
      }
    }
    .promo-unit {
      .promo-unit__headline {
        letter-spacing: 0.02em;
      }
      .promo-unit__subheader {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
      .promo-unit__copy {
        font-size: 18px;
      }
    }
  }
}

.brand-renutriv {
  &.device-mobile {
    &:not(.is-alternate) {
      .header-inner {
        .page-branding {
          .page-branding__logo {
            width: 160px;
            background-size: 160px 23px;
          }
        }
      }
    }
    .mpp {
      h2.mpp__header {
        font-size: 40px;
        letter-spacing: 0.025em;
      }
      .mpp__product-grid {
        .product-brief {
          &__header {
            font-size: 21px;
            letter-spacing: 0.025em;
          }
          &__sub-header {
            letter-spacing: 0.02em;
          }
          &__price {
            font-size: 15px;
          }
          &__button-quickshop,
          &__button-quickshop--close {
            font-size: 17px;
          }
          &__quickshop-container {
            .quickshop-inline {
              &__attribute-text {
                font-size: 21px;
                letter-spacing: 0.05em;
              }
              &__add-button {
                font-size: 17px;
                letter-spacing: 0.05em;
              }
              &__wishlist:link,
              &__wishlist:visited,
              &__details:link,
              &__details:visited {
                font-size: 15px;
              }
            }
          }
        }
      }
      h5 {
        letter-spacing: 0.02em;
      }
    }
  }
}

.brand-aerin {
  #main {
    .quickshop-inline {
      &__price-size-select-container {
        .quickshop-inline__price-size {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
    .spp-product {
      .spp-product__description {
        a {
          &.spp__phone-button {
            display: none;
          }
        }
      }
    }
  }
}

.section-foundation-finder {
  .ff-quiz {
    &__subhead {
      font-size: 17px;
    }
    &__carousel-wrapper {
      .ff-quiz__nav-text {
        .ff-quiz__nav-label {
          font-size: 20px;
          letter-spacing: 0.05em;
          line-height: 17px;
        }
        .ff-quiz__nav-value {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
      }
      .ff-quiz__slide--content {
        .ff-quiz__description {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
        .ff-quiz__question {
          .ff-quiz__headline--tertiary {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    &__filter-button-wrapper {
      .button--dark {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
    }
    &__slide--4 {
      .ff-quiz {
        &__answer {
          &.ff-quiz {
            &__answer--05 {
              display: block;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .ff-results {
    &__matches-copy {
      p {
        font-size: 17px;
        letter-spacing: 0.05em;
        line-height: 20px;
      }
    }
    &__recommended-products {
      .sku-brief-editorial__size-price-reviews {
        .text--bold {
          font-size: 20px;
          letter-spacing: 0.05em;
          font-weight: bold;
        }
      }
    }
  }
}

.page-navigation {
  .menu-reference:last-child {
    margin-bottom: 20px;
  }
}

.elc-user-state-anonymous {
  .page-navigation {
    .menu-reference--account {
      display: none;
    }
  }
}

.email_popover {
  &__social_icons {
    position: initial !important;
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      input[type='radio'] ~ label {
        &::after,
        &::before {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}
.zero-dollar {
  &-buttons {
    display: flex;
    padding-top: 40px;
    .button {
      margin: 0px auto;
      color: $color-white;
      background-color: $color-black;
    }
  }
  &-success,
  &-error {
    text-align: center;
    font-size: 28px;
    padding-top: 60px;
  }
  &-content {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    flex: 1;
    max-width: 60%;
    text-align: $ldirection;
    justify-content: center;
    align-items: flex-start;
    padding-#{$ldirection}: 30px;
  }
}

.sample-mini-bar {
  .mpp {
    &__product-grid {
      opacity: 1;
    }
  }
}

.page-node {
  .page-main {
    .multi_use_homepage_formatter {
      margin-top: -82px;
    }
  }
}
