#optanon {
  .vendor-header-container {
    #optanon-popup-more-info-bar {
      width: 42%;
      @media #{$medium-up} {
        width: 33%;
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-policy-text {
        font-size: 1.2em;
      }
      #onetrust-button-group {
        font-size: 1.3em;
      }
    }
  }
}
