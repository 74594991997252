/* common styles */
.social-login {
  &__divider {
    width: 100%;
  }
  &__container {
    padding: 15px 25px;
    @media #{$medium-up} {
      padding: 20px 0;
    }
  }
  &__email-opt-in {
    display: block;
    margin-top: 20px;
    text-align: $ldirection;
    label {
      letter-spacing: 0.05em;
      text-transform: none;
    }
  }
  &__terms {
    text-align: $ldirection;
    padding: 0;
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
  }
}
/* Checkout signin page */
.page-wrapper-checkout {
  .social-login {
    &__divider {
      margin: 0 auto 20px auto;
    }
    &__container {
      padding: 0 25px;
      @media #{$medium-up} {
        padding: 0;
      }
    }
  }
}
/* Account Profile Page */
.social-info {
  padding-top: 7%;
  border-top: none;
  padding-#{$ldirection}: 19px;
  @media #{$medium-only} {
    padding-top: 0;
  }
  @media #{$large-up} {
    margin-#{$ldirection}: 25%;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 20%;
  }
  &__header {
    font-size: 20px;
    @media #{$medium-up} {
      font-size: 30px;
    }
  }
  &__connect {
    font-family: 'AkzidenzGrotesk W1G';
  }
  &__content p {
    font-size: 12px;
    a {
      text-decoration: underline;
    }
  }
}

.account-profile__connected-accounts {
  font-family: 'AkzidenzGrotesk W1G';
}

.device-pc {
  .sidebar-page {
    position: static;
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    margin-#{$rdirection}: 0;
  }
  .social-login {
    &__container {
      text-align: center;
      padding: 15px 25px;
      @media #{$medium-up} {
        padding: 20px 0;
      }
    }
    &__divider {
      width: 100%;
    }
  }
}
/* Order confirmation page */
#confirm {
  .page-wrapper {
    .social-login {
      &__contents {
        margin-top: 15px;
      }
      &__opt-in-label {
        font-size: 15px;
      }
      padding: 0;
    }
    .social-login__title {
      font-size: 40px;
      @media #{$medium-up} {
        font-size: 50px;
      }
    }
    .social-login__info,
    .social-login__email-opt-in {
      display: block;
      padding: 0 24px;
      text-align: $ldirection;
      @media #{$medium-up} {
        padding: 0;
      }
    }
    .social-login__container {
      padding: 0;
      text-align: center;
      @media #{$medium-up} {
        text-align: $ldirection;
      }
    }
  }
}
/* Appointment booking */
.appointments-container {
  .social-login__container {
    padding: 20px 0;
  }
}
