.postal_code_container {
  @media #{$medium-up} {
    height: 30px;
    label {
      margin-#{$ldirection}: 20px;
    }
  }
  input {
    padding: 12px 35px;
  }
  .icon {
    height: 15px;
    width: 15px;
    &--search {
      position: relative;
      #{$ldirection}: 0.5rem;
      top: -2rem;
    }
    &--dropdown {
      position: relative;
      top: -3rem;
      float: $rdirection;
      #{$rdirection}: 10px;
    }
  }
  .address-search-results {
    background: $color-grey-light;
    position: sticky;
    z-index: 1;
  }
}

.sign-in-page {
  .postal_code_container {
    .icon {
      &--search {
        top: -1.5rem;
      }
      &--dropdown {
        top: -2.5rem;
      }
    }
  }
  .ship_type_container {
    display: flex;
  }
  .address-search-results {
    position: absolute;
  }
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 26px;
  }
  @media #{$xlarge-up} {
    left: 50px;
  }
}

.signin-overlay-wrapper {
  .sign-in-component {
    @media #{$medium-up} {
      .form-item {
        input {
          width: 300px;
        }
      }
    }
    .postal_code_container {
      height: auto;
      margin-bottom: -10px;
      .icon {
        &--search {
          top: -1.5rem;
        }
        &--dropdown {
          top: -2.5rem;
        }
      }
    }
  }
}
