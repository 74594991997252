@if $password_strengthen {
  #signin {
    .sign-in-component {
      &__password-wrapper {
        &.password-field {
          overflow: visible;
        }
      }
    }
  }
  .registration-page {
    &__content {
      .profile-info {
        &__change_pwd_link {
          margin-top: 8px;
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__rules {
        font-size: 9px;
        @media #{$medium-up} {
          font-size: 12px;
        }
      }
    }
  }
  .password-field {
    &__info {
      #signin & {
        @media #{$medium-up} {
          -webkit-transform: translateY(-27%);
          -moz-transform: translateY(-27%);
          -o-transform: translateY(-27%);
          transform: translateY(-27%);
          top: -10px;
          width: 48%;
          #{$rdirection}: -6%;
          &::before {
            #signin &,
            #confirm &,
            #password_reset & {
              @media #{$medium-up} {
                top: 43%;
                #{$ldirection}: -7%;
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      #signin & {
        @media #{$medium-up} {
          #{$rdirection}: -7%;
          &::before {
            #{$ldirection}: -7%;
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
        &::before {
          #confirm & {
            @media #{$medium-up} {
              content: '';
              position: absolute;
              top: 100%;
              #{$rdirection}: 51%;
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              -moz-transform: rotate(270deg);
              transform: rotate(270deg);
            }
          }
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: -20%;
          width: 188px;
          #{$ldirection}: 35%;
          -webkit-transform: translateY(3%);
          -moz-transform: translateY(3%);
          -o-transform: translateY(3%);
          transform: translateY(3%);
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$ldirection}: 65%;
          top: -39px;
          width: 35%;
        }
      }
      &-checkout {
        @media #{$medium-up} {
          margin-top: 5px;
          #{$ldirection}: 97%;
          position: absolute;
          border: 1px solid $color-black;
          width: 200px;
          -webkit-transform: translateY(-74%);
          -moz-transform: translateY(-74%);
          -o-transform: translateY(-74%);
          transform: translateY(-74%);
        }
        &::before {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 43%;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
    }
    &__rules {
      font-size: 13px;
      .checkout & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
  .sign-in-page {
    .sign-in-component {
      &__form--registration {
        .sign-in-component__password-wrapper.password-field {
          overflow: visible;
        }
      }
    }
  }
}
